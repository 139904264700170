<script lang="ts">
    import { Drawer, DrawerContent, DrawerTrigger } from '$lib/components/ui/drawer';
    import { inertia } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    import { page, router } from '@inertiajs/svelte';
    import { derived, writable } from 'svelte/store';

    let theme: 'light' | 'dark' = 'light';
    let sidebarOpen = writable(false);

    let userData = localStorage.getItem('userData');
    let user = userData ? JSON.parse(userData) : null;

    function getCookie(name: string): string | null {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? match[2] : null;
    }

    function setCookie(name: string, value: string, days: number) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }
        document.cookie = `${name}=${value}; path=/;${expires}`;
    }

    function changeMode(): void {
        theme = getCookie('app_theme') === 'dark' ? 'light' : 'dark';
        setCookie('app_theme', theme, 365);
        document.documentElement.classList.toggle('dark', theme === 'dark');
        document.body.dataset.theme = theme;
    }

    onMount(() => {
        const savedTheme = getCookie('app_theme');

        if (savedTheme === 'dark' || savedTheme === 'light') {
            theme = savedTheme;
        } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            theme = 'dark';
        }

        document.documentElement.classList.toggle('dark', theme === 'dark');
        document.body.dataset.theme = theme;
    });

    function handleKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Enter' || event.key === ' ') {
            changeMode();
            event.preventDefault();
        }
    }

    const currentPath = derived(page, ($page) => $page?.url);

    function handleLogout() {
        router.post(
            '/logout',
            {},
            {
                onFinish: () => {
                    // document.cookie.split(';').forEach((cookie) => {
                    //     document.cookie = cookie
                    //         .replace(/^ +/, '')
                    //         .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
                    // });

                    localStorage.clear();
                    sessionStorage.clear();

                    window.location.replace('/login');
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 50);
                },
            }
        );
    }
    function navigate(link: string, e: Event) {
        sidebarOpen.set(false); // Close sidebar on navigation

        if (link === '/rounds') {
            window.location.href = '/rounds';
        } else {
            router.visit(link, { preserveState: false, replace: false });
        }
    }
    type MenuItem = {
        icon: string;
        label: string;
        link?: string;
        isDrawer?: boolean;
    };

    const menuItems: MenuItem[] = [
        { icon: 'home-outline', label: 'Home', link: '/feed' },
        { icon: 'notifications-outline', label: 'Notifications', link: '/my/notifications' },
        { icon: 'sparkles-outline', label: 'Explore', link: '/explore/grid' },
        { icon: 'chatbubbles-outline', label: 'Messages', link: '/my/messenger' },
        { icon: 'wallet-outline', label: 'Wallet', link: '/wallet' },
        { icon: 'cart-outline', label: 'Markets', link: '/markets' },
        { icon: 'settings-outline', label: 'Settings', link: '/my/settings' },
        { icon: 'ellipsis-horizontal-outline', label: 'More', isDrawer: true },
    ];
    const drawerItems = [
        { label: 'My profile', icon: 'person-circle-outline', link: `/${user?.username}` },
        { label: 'Bookmarks', icon: 'bookmarks-outline', link: '/my/bookmarks' },
        { label: 'Lists', icon: 'list-outline', link: '/my/lists' },
        { label: 'Subscriptions', icon: 'people-outline', link: '/my/settings/subscriptions' },
        { label: 'Analytics', icon: 'bar-chart-outline', link: '/analytics/engagement' },
        { label: 'Rounds', icon: 'people-circle-outline', link: '/rounds' },
        { label: 'Help and support', icon: 'help-circle-outline', link: '/contact' },
    ];
    const bottomNavItems = [
        { icon: 'home-outline', filledIcon: 'home', link: '/feed' },
        { icon: 'search-outline', filledIcon: 'search', link: '/search' },
        { icon: 'add-circle-outline', filledIcon: 'add-circle', link: '/posts/create' },
        { icon: 'chatbubbles-outline', filledIcon: 'chatbubbles', link: '/my/messenger' },
        { icon: 'menu-outline', isDrawer: true },
    ];
</script>

<div class="hidden w-[26.4rem] p-2 md:block">
    <a
        href="/{user?.username}"
        use:inertia
        class="mb-6 flex cursor-pointer items-center space-x-3 pt-4 transition hover:opacity-80"
    >
        <img src="/svg/111.webp" alt="profile" class="h-14 w-14 rounded-full" />
        <div class="text-gray-500">
            <h6 class="text-md m-0 font-semibold">{user?.name}</h6>
            <p class="text-md m-0">{user?.username}</p>
        </div>
    </a>

    <nav class="flex flex-col gap-[0.5rem] space-y-4">
        {#each menuItems as item}
            {#if item.isDrawer}
                <Drawer bind:open="{$sidebarOpen}">
                    <DrawerTrigger class="!mt-0">
                        <button
                            type="button"
                            class=" flex w-full items-center space-x-3 rounded-2xl px-3 py-2 transition hover:bg-[#6365f134] hover:text-blue-600 dark:hover:text-blue-200"
                        >
                            <ion-icon class="h-6 w-6 text-gray-500" name="ellipsis-horizontal-circle-outline"
                            ></ion-icon>
                            <span class="text-[18px] font-normal text-gray-500">More</span>
                        </button>
                    </DrawerTrigger>

                    <DrawerContent class="fixed h-full w-64 !border-none  dark:!bg-[#2a2a2a]">
                        <div class="flex h-full flex-col justify-between">
                            <div>
                                <div class="mb-6 flex items-center space-x-2 pl-6 pt-6">
                                    {#if theme !== 'dark'}
                                        <img src="/svg/logo-black.png" alt="" class="w-[50%]" />
                                    {:else}
                                        <img src="/svg/logo-white.png" alt="" class="w-[50%]" />
                                    {/if}
                                </div>

                                <nav class="w-full space-y-1 border-none">
                                    {#each drawerItems as item, index}
                                        {#if index === drawerItems?.length - 1}
                                            {#if theme !== 'dark'}
                                                <hr class=" border-gray-200" />
                                            {:else}
                                                <hr class=" border-gray-500" />
                                            {/if}
                                        {/if}

                                        <a
                                            href="{item.link}"
                                            on:click="{(e) => {
                                                e.preventDefault();
                                                sidebarOpen.set(false);
                                                navigate(item.link, e);
                                            }}"
                                            class="flex w-full items-center space-x-3 py-[10px] pl-6 text-[#7e7e7e] transition hover:bg-[#f5f4f6] hover:text-[#374151] dark:hover:bg-[gray] dark:hover:text-white"
                                        >
                                            <ion-icon class="h-6 w-6" name="{item.icon}"></ion-icon>
                                            <span class="text-md font-[500]">{item.label}</span>
                                        </a>
                                    {/each}
                                </nav>
                                <span
                                    id="darkmode"
                                    role="button"
                                    tabindex="0"
                                    on:click="{changeMode}"
                                    on:keydown="{handleKeyDown}"
                                >
                                    <div class="darkmode_icon">
                                        <span class="ray"></span>
                                        <span class="ray"></span>
                                        <span class="ray"></span>
                                        <span class="ray"></span>
                                        <span class="ray"></span>
                                        <span class="ray"></span>
                                        <span class="ray"></span>
                                        <span class="ray"></span>
                                    </div>
                                </span>
                            </div>

                            <div class="mb-2 flex h-full items-end justify-center pt-6">
                                <button
                                    on:click="{handleLogout}"
                                    class="flex w-[90%] items-center justify-start rounded-lg bg-[#ef3e3e1a] py-2 pl-3 text-[16px] text-[#f87171] transition hover:bg-red-200"
                                >
                                    <ion-icon class="h-6 w-6" name="log-out-outline"></ion-icon>
                                    <span class="text-md">Log out</span>
                                </button>
                            </div>
                        </div>
                    </DrawerContent>
                </Drawer>
            {:else}
                <a
                    href="{item.link}"
                    use:inertia
                    class="mt-0 flex items-center space-x-3 rounded-2xl px-3 py-2 text-[18px] font-normal transition
                {$currentPath === item.link
                        ? 'bg-[#6366F133] text-[#6366f1]'
                        : 'text-gray-500 hover:bg-[#6366F11C] hover:text-[#6366f1] dark:hover:text-blue-200'}"
                >
                    <ion-icon class="h-6 w-6" name="{item.icon}"></ion-icon>
                    <span class="text-md font-normal">{item.label}</span>
                </a>
            {/if}
        {/each}
    </nav>

    <div class="mt-3">
        <div class="sparkle-button mt-2">
            <button on:click="{() => (window.location.href = '/posts/create')}" use:inertia>
                <span class="spark"></span>
                <span class="backdrop"></span>
                <svg class="sparkle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <span class="text">New post</span>
            </button>
            <span aria-hidden="true" class="particle-pen">
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg><svg class="particle" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.937 3.846L7.75 1L8.563 3.846C8.77313 4.58114 9.1671 5.25062 9.70774 5.79126C10.2484 6.3319 10.9179 6.72587 11.653 6.936L14.5 7.75L11.654 8.563C10.9189 8.77313 10.2494 9.1671 9.70874 9.70774C9.1681 10.2484 8.77413 10.9179 8.564 11.653L7.75 14.5L6.937 11.654C6.72687 10.9189 6.3329 10.2494 5.79226 9.70874C5.25162 9.1681 4.58214 8.77413 3.847 8.564L1 7.75L3.846 6.937C4.58114 6.72687 5.25062 6.3329 5.79126 5.79226C6.3319 5.25162 6.72587 4.58214 6.936 3.847L6.937 3.846Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </span>
        </div>
    </div>
</div>
<div class="fixed bottom-0 left-0 right-0 z-50 block h-[70px] bg-[#F9FAFB] shadow-md dark:bg-gray-900 md:hidden">
    <nav class="flex items-center justify-around py-3">
        {#each bottomNavItems as item}
            {#if item.isDrawer}
                <button
                    on:click="{() => sidebarOpen.set(true)}"
                    class="flex flex-col items-center rounded-lg px-4 py-2 text-gray-500 transition dark:text-gray-300"
                >
                    <ion-icon class="h-6 w-6" name="menu-outline"></ion-icon>
                </button>
            {:else}
                <a
                    href="{item.link}"
                    class="flex flex-col items-center rounded-lg px-4 py-2 text-[28px] text-gray-500 transition dark:text-gray-300"
                >
                    <ion-icon class="h-6 w-6" name="{$currentPath === item.link ? item.filledIcon : item.icon}"
                    ></ion-icon>
                </a>
            {/if}
        {/each}
    </nav>
</div>

<style global>
    .s-Dbc_oWBRmt5Z {
        display: none;
    }
    :global(:root) {
        --header: #000;
        --background: #fff;
        --page-color-transition: background 0.2s ease, color 0.2s ease;
    }

    :global([data-theme='dark']) {
        --header: #fff;
        --background: #fff;
    }

    :global(body) {
        background: var(--background);
        transition:
            transform 0.5s ease-in-out,
            var(--page-color-transition);
    }

    #darkmode {
        padding: 0.5em 0.5em;
        cursor: pointer;
        width: 40px;
        height: 40px;
        position: absolute;
        margin-left: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: start;
        transform: scale(1);
    }

    .darkmode_icon {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: var(--header);
        transform-origin: center center;
        transition:
            transform 0.75s ease-in-out,
            var(--page-color-transition);
    }

    .darkmode_icon::after {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        left: 8px;
        bottom: 4px;
        border-radius: 10px;
        background: var(--background);
        transform-origin: center center;
        transition:
            transform 0.5s ease,
            left 0.25s ease,
            bottom 0.25s ease,
            var(--page-color-transition);
    }

    .darkmode_icon .ray {
        position: absolute;
        left: 7px;
        top: 7px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: var(--header);
        transform-origin: center;
        transition:
            transform 0.5s ease-in-out,
            var(--page-color-transition);
    }

    .ray:nth-child(1) {
        transform: rotate(45deg) translateX(0);
    }
    .ray:nth-child(2) {
        transform: rotate(90deg) translateX(0);
    }
    .ray:nth-child(3) {
        transform: rotate(135deg) translateX(0);
    }
    .ray:nth-child(4) {
        transform: rotate(180deg) translateX(0);
    }
    .ray:nth-child(5) {
        transform: rotate(225deg) translateX(0);
    }
    .ray:nth-child(6) {
        transform: rotate(270deg) translateX(0);
    }
    .ray:nth-child(7) {
        transform: rotate(315deg) translateX(0);
    }
    .ray:nth-child(8) {
        transform: rotate(360deg) translateX(0);
    }

    :global([data-theme='dark']) .darkmode_icon {
        transform: scale(0.6);
    }

    :global([data-theme='dark']) .darkmode_icon::after {
        left: 15px;
        bottom: 8px;
        transform: scale(0);
    }

    :global([data-theme='dark']) .ray:nth-child(1) {
        transform: rotate(45deg) translateX(-16px);
    }
    :global([data-theme='dark']) .ray:nth-child(2) {
        transform: rotate(90deg) translateX(-16px);
    }
    :global([data-theme='dark']) .ray:nth-child(3) {
        transform: rotate(135deg) translateX(-16px);
    }
    :global([data-theme='dark']) .ray:nth-child(4) {
        transform: rotate(180deg) translateX(-16px);
    }
    :global([data-theme='dark']) .ray:nth-child(5) {
        transform: rotate(225deg) translateX(-16px);
    }
    :global([data-theme='dark']) .ray:nth-child(6) {
        transform: rotate(270deg) translateX(-16px);
    }
    :global([data-theme='dark']) .ray:nth-child(7) {
        transform: rotate(315deg) translateX(-16px);
    }
    :global([data-theme='dark']) .ray:nth-child(8) {
        transform: rotate(360deg) translateX(-16px);
    }
</style>
